import React, { useEffect } from 'react';

import { StaticQuery, graphql } from 'gatsby';

import ComparisonChart from '../components/ComparisonChart';
import CreditScoreDifference from '../components/CreditScoreDifference';
import Faq from '../components/Faq/Faq';
import FlodeskCreditChallengeForm from '../components/Flodesk/CreditChallengeForm';
import HomeFeatures from '../components/Home/HomeFeatures';
import HomeHero from '../components/Home/HomeHero';
import HomeHowItWorks from '../components/Home/HomeHowItWorks';
import InterestChart from '../components/InterestChart';
import Testimonials from '../components/Testimonials';
import '../scss/styles.scss';
import CreditVocab from '../components/CreditVocab';
import CreditCurriculum from '../components/CreditCurriculum';
import BasicPanel from '../components/BasicPanel';
import VideoClips from '../components/VideoClips';

const Home1 = ({ location }) => {
  return (
    <StaticQuery
      query={graphql`
        query CombinedQuery {
          site {
            siteMetadata {
              app_url
            }
          }
          allContentfulLandingPage {
            nodes {
              slug
              mainHeader {
                childMarkdownRemark {
                  html
                }
              }
              heroSubheader {
                childMarkdownRemark {
                  html
                }
              }
              heroButtonText
              heroSubtext
              mobileHeroImage {
                url
                title
                mimeType
              }
              desktopHeroImage {
                url
                title
                mimeType
              }
              pressFeatureLogos {
                url
                title
              }
              testimonialHeader {
                childMarkdownRemark {
                  html
                }
              }
              testimonialData {
                title
                content
                customerName
                customerAge
              }
              interestChartData {
                bank
                apy
                rate
                fees
              }  
              interestChartSection {
                header
                inputLabel
                calculateButton
                estimatedEarningsHeader
                overdraftFeesHeader
                disclaimer {
                  part1
                  linkText
                  linkUrl
                  part2
                }
                popupHeader
                popupText
                submitButton
                dailyBalancePlaceholder
                membershipFeeHeader
              }
              pinkTaxPanelContent {
                title
                subtitle
                body
                cta
              }
              pinkTaxPanelImage {
                url
                title
              }
              howItWorksData {
                header
                additionalText {
                  joinToday
                  moreQuestions
                }
                howItWorksSteps {
                  heading
                  description
                }
              }
              howItWorksImages {
                url
                title
              }
            }
          }
        }
      `}
      render={data => {
        const content = data?.allContentfulLandingPage?.nodes[0];
        const testimonialHeader = content?.testimonialHeader;
        const testimonials = content?.testimonialData;
        const interestChartData = content?.interestChartData;
        const interestChartSection = content?.interestChartSection;
        const howItWorksData = content?.howItWorksData;
        const howItWorksImages = content?.howItWorksImages;
        const pinkTaxPanel = content?.pinkTaxPanelContent;
        const pinkTaxPanelImage = content?.pinkTaxPanelImage;
        //const videoClips = content?.videoClips;

        return (
          <main>
            <HomeHero content={content} />
            <HomeFeatures content={content} />
            {/* <VideoClips content={videoClips} /> */}
            <CreditScoreDifference />
            <CreditVocab />


            {/* <ComparisonChart />
            <InterestChart
              interestChartData={interestChartData}
              interestChartSection={interestChartSection}
            /> */}

            <BasicPanel
              title={pinkTaxPanel.title}
              subtitle={pinkTaxPanel.subtitle}
              body={pinkTaxPanel.body}
              headerImage={pinkTaxPanelImage}
              cta={pinkTaxPanel.cta}
            />


            <CreditCurriculum />

            <Testimonials header={testimonialHeader} testimonials={testimonials} />
            <HomeHowItWorks
              howItWorksData={howItWorksData}
              howItWorksImages={howItWorksImages}
            />
            {/* <FlodeskCreditChallengeForm /> */}
            <Faq />
          </main>
        );
      }}
    />
  );
};

export default Home1;
